import React from "react"
import { graphql } from "gatsby"
import Container from "../components/common/Container"
import Layout from "./../components/layouts/Default"
import { H2 } from "../components/typography/Headings"
import PageHeader from "../components/common/PageHeader"

export default function Page({ data, location }) {
  return (
    <Layout location={location} title="Kontakt" desc="">
      <PageHeader title="Anfrage gesendet" image={data.header} />

      <Container narrow>
        <H2 className="font-display">Anfrage gesendet</H2>
        <hr className="my-8 border-t border-gray-100" />
        <p>Wir bearbeiten Ihre E-Mail zeitnah.</p>
      </Container>
    </Layout>
  )
}

export const query = graphql`
  query {
    header: file(relativePath: { eq: "pages/kontakt/header.jpg" }) {
      childImageSharp {
        gatsbyImageData
      }
    }
    peter: file(relativePath: { eq: "pages/index/peter.jpg" }) {
      childImageSharp {
        gatsbyImageData
      }
    }
  }
`
